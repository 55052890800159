import axios from 'axios'
import {saveAs} from 'file-saver'

const keyMap = {
  destWalletName: 'Destination Account Name',
  amount: 'Amount',
  srcWalletName: 'Source Account Name',
  completedDT: 'Completed DateTime',
  fee: 'Fee',
  merchantTxnId: 'Merchant Transaction ID',
  receivedDT: 'Received DateTime',
  submittedTxnId: 'Submitted Transaction ID',
  destWalletAcc: 'Destination Account Number',
  referenceId: 'Reference ID',
  merchantName: 'Merchant Name',
  walletCd: 'Bank',
  transType: 'Transaction Type',
  srcWalletAcc: 'Source Account Number',
  clientIP: 'Client IP',
  submittedDT: 'Submitted DateTime',
  customerCd: 'Customer Code',
  merchantCd: 'Merchant Code',
  status: 'Transaction Status',
  matchStatus: 'Match Status',
  matchedTxnId: 'Matched Txn ID',
  successBy: 'Transaction Success By',
  agentComm: 'Agent Commission',
  txnAgentCommFee: 'Agent Commission Fee',
  // Add more key mappings here
}

// Function to rearrange the dataset based on keyMap
function rearrangeDataset(originalData: any, keyMap: any) {
  return originalData.map((item: any) => {
    const rearrangedItem: any = {}
    for (const key in keyMap) {
      rearrangedItem[keyMap[key]] = item[key]
    }
    return rearrangedItem
  })
}

function generateFile(
  formData: any,
  keyMap: any,
  startDate: string,
  endDate: string,
  fileName: string
) {
  const XLSX = require('xlsx')
  let dataset = rearrangeDataset(formData, keyMap) // Use rearrangeDataset instead of modifyKeys
  dataset = dataset.map((obj: any) => {
    const updatedObj = {...obj}
    // Replace commas in merchantName with a different character (e.g., semicolon)
    if (updatedObj['Merchant Name']) {
      updatedObj['Merchant Name'] = obj['Merchant Name'].replace(/,/g, '; ')
    }

    if (updatedObj['Agent Name']) {
      updatedObj['Agent Name'] = obj['Agent Name'].replace(/,/g, '; ')
    }

    if (updatedObj['Request DateTime']) {
      updatedObj['Request DateTime'] = obj['Request DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Created Date']) {
      updatedObj['Created Date'] = obj['Created Date'].replace('T', ' ').replace(/\.\d+/, '')
    }

    if (updatedObj['Deactivated Date']) {
      updatedObj['Deactivated Date'] = obj['Deactivated Date']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Completed DateTime']) {
      updatedObj['Completed DateTime'] = obj['Completed DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Received DateTime']) {
      updatedObj['Received DateTime'] = obj['Received DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    delete updatedObj.merchantCd
    return updatedObj
  })
  const worksheet = XLSX.utils.json_to_sheet(dataset)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'})
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, `${fileName} ${startDate} - ${endDate}.xlsx`) // Requires the FileSaver library or a similar method
}

export default function DetailedReporting_Export_Excel(filterValues: any, setLoading: any): void {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  setLoading(true)

  let merchantCd = filterValues.merchantCd.trim()
  let timeZone = filterValues.timeZone.trim()
  let basedOn = filterValues.basedOn.trim()
  let transType = filterValues.transType.trim()
  let dateTimeFrom = filterValues.dateTimeFrom.trim()
  let dateTimeTo = filterValues.dateTimeTo.trim()
  let wallets = filterValues.wallets

  //   values.wallets = wallets

  //   setStartDate(dateTimeFrom)
  //   setEndDate(dateTimeTo)

  //   setFilterOptions(values)

  let submissionData = {}

  if (filterValues.merchantCd !== '') {
    submissionData = {
      session: session,
      merchantCd: merchantCd,
      ...(wallets && {accountNos: wallets}),
      timezone: timeZone,
      basedOn: basedOn,
      transType: transType,
      fromDate: dateTimeFrom,
      toDate: dateTimeTo,
      //Pagination
      noOfRec: 0,
      offSet: 0,
    }
  } else {
    submissionData = {
      session: session,
      timezone: timeZone,
      ...(wallets && {accountNos: wallets}),
      basedOn: basedOn,
      transType: transType,
      fromDate: dateTimeFrom,
      toDate: dateTimeTo,
      //Pagination
      noOfRec: 0,
      offSet: 0,
    }
  }

  axios
    .post(
      `${SISPAY_API}/merchant/admin/detailedreport`,
      {
        request: submissionData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response) {
      let formData = response.data.result.merchantDetailedReport

      generateFile(formData, keyMap, dateTimeFrom, dateTimeTo, 'Detailed Report')

      setLoading(false)
    })
    .catch(function (error) {})
}
