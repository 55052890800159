import axios from 'axios'
import {useState} from 'react'
import {useDepositStore} from './Deposit_Store'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

export function ManualMatching(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let depositData = props.depositData

  //States
  const [loading, setLoading] = useState(false)

  //Zustand Store
  const setGetData = useDepositStore((state: any) => state.setGetData)

  function isDepositURL() {
    var pattern = /^\/deposit\/\d+$/
    var url = window.location.pathname // Get the current URL path

    return pattern.test(url)
  }

  const initialValues = {
    // txnId: '',
    txnId: depositData.walletCd == 'BKASH' ? String(depositData.submittedTxnId) : '',
    session: session,
  }

  const validationSchema = Yup.object().shape({
    txnId: Yup.string().required('TxnId is required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        axios
          .post(
            `${SISPAY_API}/cash/depositrequest/approve`,
            {
              request: {
                session: session,
                approvedTxnId: values.txnId,
                id: depositData.id,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response: any) {
            if (response.data.code == '200') {
              document.getElementById('manualMatchingModalCloseBtn')?.click()
              setGetData(true)
              alert('Approved Successfully')
            } else {
              setGetData(true)
              alert(response.data.message)
            }
            setLoading(false)
          })
          .catch(function (error) {
            alert(error)
          })
      } catch (error) {}
    },
  })

  return (
    <div>
      <div
        className='modal fade'
        id='manualMatchingModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <form onSubmit={formik.handleSubmit} className=''>
              <div className='modal-header'>
                <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                  Manual Matching
                </h1>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                {/* begin::Form group */}
                <div
                  className='fv-row mb-8'
                  hidden={depositData.walletCd == 'BKASH' ? true : false}
                >
                  <label className='form-label required fw-bolder text-dark fs-6'>
                    Match TxnID{' '}
                  </label>
                  <input
                    placeholder='Enter Match TxnId'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('txnId')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.txnId && formik.errors.txnId,
                      },
                      {
                        'is-valid': formik.touched.txnId && !formik.errors.txnId,
                      }
                    )}
                  />
                  {formik.touched.txnId && formik.errors.txnId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.txnId}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
                <div hidden={depositData.walletCd == 'BKASH' ? false : true}>
                  Do you want to approve this deposit request?
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  id='manualMatchingModalCloseBtn'
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'
                >
                  Close
                </button>
                {/* begin::Form group */}
                <div className='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100'
                    disabled={formik.isSubmitting || !formik.isValid || loading}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>{' '}
                {/* end::Form group */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
