import axios from 'axios'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function generateFile(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A1' as 'A1',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'Created DateTime',
              'Submitted DateTime',
              'Completed DateTime',
              'Merchant Name',
              'Merchant Code',
              'Customer Code',
              'Bank',
              'Amount',
              'Transaction Type',
              'Transaction Status',
              // 'Transaction Success By',
              'Fee',
              'Merchant Transaction ID',
              'Submitted Transaction ID',
              'Reference ID',
              'Source Account No',
              'Source Account Name',
              'Destination Account No',
              'Destination Account Name',
              'Match Status',
              'Matched Txn ID',
              'Agent Commission',
              'Agent Commission Fee',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.receivedDT,
              item.submittedDT,
              item.completedDT,
              item.merchantName,
              item.merchantCd,
              item.customerCd,
              item.walletCd,
              item.amount,
              item.transType,
              item.status,
              item.fee,
              item.merchantTxnId,
              item.submittedTxnId,
              item.referenceId,
              item.srcWalletAcc,
              item.srcWalletName,
              item.destWalletAcc,
              item.destWalletName,
              item.matchStatus,
              item.matchedTxnId,
              item.agentComm,
              item.txnAgentCommFee,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}

export default function DetailedReporting_Export_PDF(filterValues: any, setLoading: any): void {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  setLoading(true)

  let merchantCd = filterValues.merchantCd.trim()
  let timeZone = filterValues.timeZone.trim()
  let basedOn = filterValues.basedOn.trim()
  let transType = filterValues.transType.trim()
  let dateTimeFrom = filterValues.dateTimeFrom.trim()
  let dateTimeTo = filterValues.dateTimeTo.trim()
  let wallets = filterValues.wallets

  //   values.wallets = wallets

  //   setStartDate(dateTimeFrom)
  //   setEndDate(dateTimeTo)

  //   setFilterOptions(values)

  let submissionData = {}

  if (filterValues.merchantCd !== '') {
    submissionData = {
      session: session,
      merchantCd: merchantCd,
      ...(wallets && {accountNos: wallets}),
      timezone: timeZone,
      basedOn: basedOn,
      transType: transType,
      fromDate: dateTimeFrom,
      toDate: dateTimeTo,
      //Pagination
      noOfRec: 0,
      offSet: 0,
    }
  } else {
    submissionData = {
      session: session,
      timezone: timeZone,
      ...(wallets && {accountNos: wallets}),
      basedOn: basedOn,
      transType: transType,
      fromDate: dateTimeFrom,
      toDate: dateTimeTo,
      //Pagination
      noOfRec: 0,
      offSet: 0,
    }
  }

  axios
    .post(
      `${SISPAY_API}/merchant/admin/detailedreport`,
      {
        request: submissionData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response) {
      let formData = response.data.result.merchantDetailedReport

      generateFile(formData, dateTimeFrom, dateTimeTo)

      setLoading(false)
    })
    .catch(function (error) {})
}
