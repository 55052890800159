import axios from 'axios'
import {usePendingDepositStore} from './PendingDeposit_Store'
import {useDepositStore} from './Deposit_Store'

export function MatchSMSModal_Nagad(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //Props

  //States

  //Zustand Store
  const setPendingGetData = usePendingDepositStore((state: any) => state.setGetData)
  const setGetData = useDepositStore((state: any) => state.setGetData)

  // Get the current URL
  var currentUrl = window.location.href

  function verifyCurrentUrl(currentUrl: string) {
    if (currentUrl.includes('/deposit/list')) {
      setGetData(true)
    } else if (currentUrl.includes('/deposit/pending')) {
      setPendingGetData(true)
    } else {
      console.log('Current URL does not match the specified patterns')
    }
  }

  function triggerMatchingSMS() {
    axios
      .post(
        `${SISPAY_API}/cash/depositrequest/admin/matchsmsmaster`,
        {
          request: {
            session: session,
            walletCd: 'NAGAD',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response: any) {
        verifyCurrentUrl(currentUrl)
        alert(response.data.message)
      })
      .catch(function (error) {
        // verifyCurrentUrl(currentUrl)
        alert(error)
      })
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_match_sms_nagad'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Trigger matching of PENDING deposits (NAGAD) using SMS
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            Do you wish to trigger matching of PENDING deposits (NAGAD) using SMS?
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            <button
              type='button'
              data-bs-dismiss='modal'
              className='btn btn-danger'
              onClick={() => {
                triggerMatchingSMS()
              }}
            >
              Understood
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
