import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {sortByDate} from '../../components/SortByDate'
import {Overlay} from '../../components/Overlay'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth/core/Auth'
import {sortByName} from '../../components/SortByName'
import {UniqueWalletAccNo} from '../../components/UniqueWalletAccNo'
import Modal from '../../components/Modal'
import {useBulkWithdrawalStore} from './BulkWithdrawal_Store'

export function Filter_Bulk_Withdrawal(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let setTotalFormData = props.setTotalFormData
  let setSelectedArray = props.setSelectedArray
  let setSelectedWalletCdFilter = props.setSelectedWalletCdFilter
  let setCurrentPage = props.setCurrentPage
  let currentPage = props.currentPage
  const withdrawalFilterSubmit = props.withdrawalFilterSubmit
  let setFilterValues = props.setFilterValues
  let downloadLoading = props.downloadLoading

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState(Object)
  const [walletAccNoList, setWalletAccNoList] = useState(Object)

  //Refs
  const selectedMerchantCd = useRef<any>()
  const selectedWalletAccNo = useRef<any>()
  const selectedWithdrawalStatusRef = useRef<any>()
  const selectedMatchStatusRef = useRef<any>()

  const handleMatchStatusChange = (selected: any) => {
    if (selected !== '') {
      setFilterOptions({
        ...filterOptions,
        matchStatus: selected.map((option: any) => option.value).join('|'),
      })
    } else {
      setFilterOptions({...filterOptions, matchStatus: ''})
    }
  }

  //Zustand Store
  const getData = useBulkWithdrawalStore((state: any) => state.getData)
  const setGetData = useBulkWithdrawalStore((state: any) => state.setGetData)
  const filterOptions = useBulkWithdrawalStore((state: any) => state.filterOptions)
  const setFilterOptions = useBulkWithdrawalStore((state: any) => state.setFilterOptions)

  const handleSelectedWithdrawalStatusChange = (selected: any) => {
    if (selected !== '') {
      setFilterOptions({
        ...filterOptions,
        withdrawalStatus: selected.map((option: any) => option.value).join('|'),
      })
    } else {
      setFilterOptions({...filterOptions, withdrawalStatus: ''})
    }
  }

  const initialValues = {
    searchCustomer: filterOptions.searchCustomer ? String(filterOptions.searchCustomer) : '',
    searchTransaction: filterOptions.searchTransaction
      ? String(filterOptions.searchTransaction)
      : '',
    merchantCd: filterOptions.merchantCd ? String(filterOptions.merchantCd) : '',
    walletCd: filterOptions.walletCd ? String(filterOptions.walletCd) : '',
    walletAccNo: filterOptions.walletAccNo ? String(filterOptions.walletAccNo) : '',
    withdrawalStatus: filterOptions.withdrawalStatus ? String(filterOptions.withdrawalStatus) : '',
    callbackStatus: filterOptions.callbackStatus ? String(filterOptions.callbackStatus) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
    matchStatus: filterOptions.matchStatus ? String(filterOptions.matchStatus) : '',
    timeZone: filterOptions.timeZone ? String(filterOptions.timeZone) : '',
  }

  const filterSchema = Yup.object().shape({
    searchCustomer: Yup.string(),
    searchTransaction: Yup.string(),
    walletCd: Yup.string().required('Wallet Code is required'),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setDisabled(true)
      let searchCustomer = values.searchCustomer.trim()
      let searchTransaction = values.searchTransaction.trim()
      let merchantCd = values.merchantCd.trim()
      let walletCd: string = values.walletCd.trim()
      let walletAccNo: string = values.walletAccNo.trim()
      let withdrawalStatus = filterOptions.withdrawalStatus
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()
      let callbackStatus = values.callbackStatus.trim()
      let matchStatus = filterOptions.matchStatus
      let timeZone = values.timeZone.trim()

      values.matchStatus = matchStatus
      values.withdrawalStatus = withdrawalStatus

      setFilterValues(values)
      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/cash/withdrawalrequest/list`,
          {
            request: {
              action: 'list',
              session: session,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              page: '1',
              //Filter
              ...(searchCustomer && {byCustomer: searchCustomer}),
              ...(searchTransaction && {byTransaction: searchTransaction}),
              ...(withdrawalStatus && {byWDStatus: withdrawalStatus}),
              ...(merchantCd && {byMerchantCd: merchantCd}),
              ...(walletCd && {byWalletCd: walletCd}),
              ...(walletAccNo && {byWalletAccNo: walletAccNo}),
              ...(callbackStatus && {byCallbackStatus: callbackStatus.trim()}),
              ...(matchStatus && {byMatchStatus: matchStatus}),
              ...(timeZone && {timezone: timeZone}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let formData = response.data.result.depositRequestList

          setFormData(sortByDate(formData, 'withdrawalDT'))
          setTotalFormData(response.data.result.totalCount)

          setDisabled(false)
          setLoading(false)

          if (currentPage !== 0) {
            setCurrentPage(1)
          }
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false && currentPage == 0) return

    if (getData == true) {
      setCurrentPage(1)
    }

    setLoading(true)
    setDisabled(true)

    let searchCustomer = formik.values.searchCustomer.trim()
    let searchTransaction = formik.values.searchTransaction.trim()
    let merchantCd = formik.values.merchantCd.trim()
    let walletCd: string = formik.values.walletCd.trim()
    let walletAccNo: string = formik.values.walletAccNo.trim()
    let withdrawalStatus = filterOptions.withdrawalStatus
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()
    let callbackStatus = formik.values.callbackStatus.trim()
    let matchStatus = filterOptions.matchStatus
    let timeZone = formik.values.timeZone.trim()

    formik.values.matchStatus = matchStatus

    axios
      .post(
        `${SISPAY_API}/cash/withdrawalrequest/list`,
        {
          request: {
            action: 'list',
            session: session,
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
            page: currentPage,
            //Filter
            ...(searchCustomer && {byCustomer: searchCustomer}),
            ...(searchTransaction && {byTransaction: searchTransaction}),
            ...(withdrawalStatus && {byWDStatus: withdrawalStatus}),
            ...(merchantCd && {byMerchantCd: merchantCd}),
            ...(walletCd && {byWalletCd: walletCd}),
            ...(walletAccNo && {byWalletAccNo: walletAccNo}),
            ...(callbackStatus && {byCallbackStatus: callbackStatus.trim()}),
            ...(matchStatus && {byMatchStatus: matchStatus}),
            ...(timeZone && {timezone: timeZone}),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let formData = response.data.result.depositRequestList

        setFormData(sortByDate(formData, 'withdrawalDT'))
        setTotalFormData(response.data.result.totalCount)

        setGetData(false)
        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [currentPage, getData])

  function retrieveWalletAccNo(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let walletAccNoList = UniqueWalletAccNo(result)

          setWalletAccNoList(sortByName(walletAccNoList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  function retrieveMerchant(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )

          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  //GET USER LIST
  useEffect(() => {
    retrieveWalletAccNo(SISPAY_API, '/wallet/list', 'wallet')
    retrieveMerchant(SISPAY_API, '/merchant/list', 'merchant')
  }, [])

  return (
    <div>
      <div className='card card-custom card-flush mb-5'>
        <div className='menu d-flex flex-column'>
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-2 py-1'>
            <div className='mb-10'>
              <form onSubmit={formik.handleSubmit} className='m-5'>
                {' '}
                <div className='fv-row mb-5'>
                  {' '}
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group Search Customer */}
                      <div className='fv-row mb-5'>
                        <div className='d-flex'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Search Customer
                          </label>
                          {Overlay(
                            'SEARCH Customer Code, Destination Account No, Destination Account Name'
                          )}
                        </div>
                        <input
                          placeholder='SEARCH Customer Code, Destination Account No, Destination Account Name'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('searchCustomer')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid':
                                formik.touched.searchCustomer && formik.errors.searchCustomer,
                            },
                            {
                              'is-valid':
                                formik.touched.searchCustomer && !formik.errors.searchCustomer,
                            }
                          )}
                        />
                        {formik.touched.searchCustomer && formik.errors.searchCustomer && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.searchCustomer}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group*/}
                      <div className='fv-row mb-5'>
                        <div className='d-flex'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Search Transaction
                          </label>
                          {Overlay('SEARCH Transaction ID, Merchant Transaction ID')}
                        </div>
                        <input
                          placeholder='SEARCH Transaction ID, Merchant Transaction ID'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('searchTransaction')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid':
                                formik.touched.searchTransaction && formik.errors.searchTransaction,
                            },
                            {
                              'is-valid':
                                formik.touched.searchTransaction &&
                                !formik.errors.searchTransaction,
                            }
                          )}
                        />
                        {formik.touched.searchTransaction && formik.errors.searchTransaction && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.searchTransaction}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>

                        <div>
                          <Select
                            ref={selectedMerchantCd}
                            className='basic-single'
                            classNamePrefix='select'
                            options={merchantCdList}
                            styles={customStyles}
                            isClearable={true}
                            name='merchantCd'
                            value={
                              formik.values.merchantCd
                                ? {value: formik.values.merchantCd, label: formik.values.merchantCd}
                                : null
                            }
                            onChange={(e: any) =>
                              e !== null
                                ? formik.setFieldValue('merchantCd', e.value)
                                : formik.setFieldValue('merchantCd', '')
                            }
                          />
                        </div>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                        <select
                          {...formik.getFieldProps('walletCd')}
                          onChange={(e) => {
                            setSelectedWalletCdFilter(e.target.value)
                            formik.handleChange(e)
                          }}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                            },
                            {
                              'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                            }
                          )}
                          data-placeholder=''
                        >
                          <option value=''>Select wallet code</option>
                          <option value='NAGAD'>NAGAD</option>
                          <option value='BKASH'>BKASH</option>
                          <option value='ROCKET'>ROCKET</option>
                        </select>{' '}
                        {formik.touched.walletCd && formik.errors.walletCd && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.walletCd}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group Match Status */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Match Status</label>
                        <Select
                          ref={selectedMatchStatusRef}
                          isMulti
                          styles={customStyles}
                          name='matchStatus'
                          options={[
                            {value: 'UNMATCHED', label: 'Un-match'},
                            {value: 'AUTO-BOT', label: 'Auto-Bot'},
                            {value: 'AUTO-SMS', label: 'Auto-Sms'},
                            {value: 'MANUAL', label: 'Manual'},
                            {value: 'MANUAL-SMS', label: 'Manual SMS'},
                          ]}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          value={
                            filterOptions.matchStatus
                              ? filterOptions.matchStatus
                                  .split('|')
                                  .map((item: any) => ({value: item, label: item}))
                              : ''
                          }
                          isClearable={true}
                          onChange={handleMatchStatusChange}
                        />
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Wallet Account No
                        </label>

                        <div>
                          <Select
                            ref={selectedWalletAccNo}
                            className='basic-single'
                            classNamePrefix='select'
                            name='walletAccNo'
                            options={walletAccNoList}
                            isClearable={true}
                            styles={customStyles}
                            value={
                              formik.values.walletAccNo
                                ? {
                                    value: formik.values.walletAccNo,
                                    label: formik.values.walletAccNo,
                                  }
                                : null
                            }
                            onChange={(e: any) =>
                              e !== null
                                ? formik.setFieldValue('walletAccNo', e.value)
                                : formik.setFieldValue('walletAccNo', '')
                            }
                          />
                        </div>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group*/}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Withdrawal Status
                        </label>
                        <Select
                          ref={selectedWithdrawalStatusRef}
                          isMulti
                          styles={customStyles}
                          name='withdrawalStatus'
                          options={[
                            {value: 'PENDING', label: 'PENDING'},
                            {value: 'PENDING-BOT', label: 'PENDING-BOT'},
                            {value: 'IN-PROGRESS', label: 'IN-PROGRESS'},
                            {value: 'SUCCESSFUL', label: 'SUCCESSFUL'},
                            {value: 'REJECTED', label: 'REJECTED'},
                            {value: 'IN-PROGRESS-BOT', label: 'IN-PROGRESS-BOT'},
                          ]}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          value={
                            filterOptions.withdrawalStatus
                              ? filterOptions.withdrawalStatus
                                  .split('|')
                                  .map((item: any) => ({value: item, label: item}))
                              : ''
                          }
                          isClearable={true}
                          onChange={handleSelectedWithdrawalStatusChange}
                        />
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Callback Status
                        </label>
                        <div
                          style={{
                            color: 'black',
                          }}
                        >
                          <select
                            {...formik.getFieldProps('callbackStatus')}
                            className={clsx('form-control bg-transparent')}
                            data-placeholder=''
                          >
                            <option value=''>ALL</option>
                            <option value='none'>NONE</option>
                            <option value='Success'>SUCCESS</option>
                            <option value='Sent'>SENT</option>
                          </select>{' '}
                        </div>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group DateTime From */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>From</label>
                        <input
                          placeholder='Enter your start time'
                          type='date'
                          autoComplete='off'
                          {...formik.getFieldProps('dateTimeFrom')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid':
                                formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                            },
                            {
                              'is-valid':
                                formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                            }
                          )}
                        />
                        {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.dateTimeFrom}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group DateTime To */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>To</label>
                        <input
                          placeholder='Enter your end time'
                          type='date'
                          autoComplete='off'
                          {...formik.getFieldProps('dateTimeTo')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                            },
                            {
                              'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                            }
                          )}
                        />
                        {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.dateTimeTo}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Timezone</label>
                        <select
                          {...formik.getFieldProps('timeZone')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value='GMT+6'>GMT+6</option>
                          <option value='GMT+7'>GMT+7</option>
                          <option value='GMT+8'>GMT+8</option>
                        </select>{' '}
                      </div>{' '}
                    </div>
                    <div className='col'></div>
                    <div className='col'></div>
                    <div className='col'></div>
                    <div className='col'></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        className='btn btn-lg btn-light w-100 mb-5'
                        disabled={disabled || downloadLoading}
                        onClick={() => {
                          selectedMerchantCd.current.setValue('')
                          selectedWalletAccNo.current.setValue('')
                          selectedWithdrawalStatusRef.current.setValue('')
                          selectedMatchStatusRef.current.setValue('')
                          setSelectedArray([])
                          setCurrentPage(1)
                          formik.resetForm({
                            values: {
                              searchCustomer: '',
                              searchTransaction: '',
                              merchantCd: '',
                              walletCd: '',
                              walletAccNo: '',
                              withdrawalStatus: '',
                              callbackStatus: '',
                              dateTimeFrom: getCurrentDateInput(),
                              dateTimeTo: getCurrentDateInput(),
                              matchStatus: '',
                              timeZone: 'GMT+6',
                            },
                          })
                        }}
                      >
                        {<span className='indicator-label'>Reset</span>}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        ref={withdrawalFilterSubmit}
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={
                          formik.isSubmitting || !formik.isValid || disabled || downloadLoading
                        }
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {Modal(loading)}
    </div>
  )
}
