import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import MerchantFeePage_EditMerchantFee from '../modules/merchantManagement/merchantFee/MerchantFeePage_EditMerchantFee'
import SmsManagementPage from '../modules/smsManagement/SmsManagementPage'
import RequestManagementPage from '../modules/requestManagement/RequestManagementPage'
import TransactionManagementPage from '../modules/transactionManagement/TransactionManagementPage'
import TransactionManagementPage_View from '../modules/transactionManagement/TransactionManagementPage_View'
import DepositManagementPage from '../modules/depositManagement/DepositManagementPage'
import WalletListPage from '../modules/walletManagement/walletList/WalletListPage'
import WalletListPage_Add from '../modules/walletManagement/walletList/WalletListPage_Add'
import WalletListPage_Edit from '../modules/walletManagement/walletList/WalletListPage_Edit'
import WalletUtilizationPage from '../modules/walletManagement/walletUtilization/WalletUtilizationPage'
import WalletListPage_Assign from '../modules/walletManagement/walletAssignment/WalletListPage_Assign'
import WalletActivityPage from '../modules/walletManagement/walletActivity/WalletActivityPage'
import WalletActivityPage_Edit from '../modules/walletManagement/walletActivity/WalletActivityPage_Edit'
import Whitelist_Source from '../modules/whitelist/source whitelist/Whitelist_Source'
import Whitelist_Source_Edit from '../modules/whitelist/source whitelist/Whitelist_Source_Edit'
import Whitelist_Source_Add from '../modules/whitelist/source whitelist/Whitelist_Source_Add'
import Whitelist_Service from '../modules/whitelist/service center/Whitelist_Service'
import Whitelist_Service_Add from '../modules/whitelist/service center/Whitelist_Service_Add'
import Whitelist_Service_Edit from '../modules/whitelist/service center/Whitelist_Service_Edit'
import DepositManagementPage_Edit from '../modules/depositManagement/DepositManagementPage_Edit'
import RequestManagementPage_View_Url from '../modules/requestManagement/RequestManagementPage_View_Url'
import WalletAssignmentPage from '../modules/walletManagement/walletAssignment/WalletAssignmentPage'
import WalletAssignmentPage_Edit from '../modules/walletManagement/walletAssignment/WalletAssignmentPage_Edit'
import TransactionManagementPage_Pending from '../modules/transactionManagement/TransactionManagementPage_Pending'
import SmsManagementPage_View from '../modules/smsManagement/SmsManagementPage_View'
import Withdrawal_List_Page from '../modules/WithdrawalManagement/Withdrawal_List'
import Withdrawal_Pending_List from '../modules/WithdrawalManagement/Withdrawal_Pending_List'
import Bulk_Withdrawal from '../modules/WithdrawalManagement/Bulk_Withdrawal'
import Settlement_Pending_List from '../modules/settlements/Settlement_Pending_List'
import Settlement_List from '../modules/settlements/Settlement_List'
import Settlement_Create from '../modules/settlements/Settlement_Create'
import Daily_Reporting_List from '../modules/Reporting/Daily Reporting/Daily_Reporting_List'
import DepositManagementPage_Pending_List from '../modules/depositManagement/DepositManagementPage_Pending_List'
import Monthly_Reporting_List from '../modules/Reporting/Merchant Reporting/Monthly_Reporting_List'
import Detailed_Reporting_List from '../modules/Reporting/Detailed Reporting/Detailed_Reporting_List'
import RequestManagementPage_List from '../modules/requestManagement/RequestManagementPage_List'
import CreditReload_List from '../modules/Agent_Management/Credit_Reload/CreditReload_List'
import CommissionWithdrawal_List from '../modules/Agent_Management/Commission_Withdrawal/CommissionWithdrawal_List'
import AgentSummary_List from '../modules/Agent_Management/Agent_Summary/AgentSummary_List'
import AgentSummary_Details from '../modules/Agent_Management/Agent_Summary/components/AgentSummary_Details'
import CreditReload_Create from '../modules/Agent_Management/Credit_Reload/components/CreditReload_Create'
import PreviousDayAgentSummary_List from '../modules/Agent_Management/Previous_Day_Agent_Summary/PreviousDayAgentSummary_List'
import PreviousDayAgentSummary_Details from '../modules/Agent_Management/Previous_Day_Agent_Summary/components/PreviousDayAgentSummary_Details'
import CommissionWithdrawal_Comm_Create from '../modules/Agent_Management/Commission_Withdrawal/components/CommissionWithdrawal_Comm_Create'
import DepositManagementPage_ManualDeposit from '../modules/depositManagement/DepositManagementPage_ManualDeposit'
import WithdrawalManagement_ManualWithdrawal from '../modules/WithdrawalManagement/WithdrawalManagement_ManualWithdrawal'
import WalletHealth_List from '../modules/walletManagement/Wallet_Health/WalletHealth_List'
import DepositManagementPage_MatchSMS from '../modules/depositManagement/DepositManagementPage_MatchSMS'
import Summary_Daily_Reporting_List from '../modules/Reporting/Summary Daily Reporting/Summary_Daily_List'
import SMSLastActive_List from '../modules/smsLastActive/SMSLastActiveList'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const UserManagementPage = lazy(() => import('../modules/userManagement/UserManagementPage'))
  const UserManagementPage_AddUser = lazy(
    () => import('../modules/userManagement/UserManagementPage_AddUser')
  )
  const UserManagementPage_EditUser = lazy(
    () => import('../modules/userManagement/UserManagementPage_EditUser')
  )
  const MerchantListPage = lazy(
    () => import('../modules/merchantManagement/merchantList/MerchantListPage')
  )
  const MerchantListPage_AddMerchant = lazy(
    () => import('../modules/merchantManagement/merchantList/MerchantListPage_AddMerchant')
  )
  const MerchantListPage_EditMerchant = lazy(
    () => import('../modules/merchantManagement/merchantList/MerchantListPage_EditMerchant')
  )
  const MerchantFeePage = lazy(
    () => import('../modules/merchantManagement/merchantFee/MerchantFeePage')
  )
  const MerchantFeePage_AddMerchantFee = lazy(
    () => import('../modules/merchantManagement/merchantFee/MerchantFeePage_AddMerchantFee')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='user-management'
          element={
            <SuspensedView>
              <UserManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/create'
          element={
            <SuspensedView>
              <UserManagementPage_AddUser />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/edit/:username'
          element={
            <SuspensedView>
              <UserManagementPage_EditUser />
            </SuspensedView>
          }
        />
        <Route
          path='merchant/list'
          element={
            <SuspensedView>
              <MerchantListPage />
            </SuspensedView>
          }
        />
        <Route
          path='merchant/list/create'
          element={
            <SuspensedView>
              <MerchantListPage_AddMerchant />
            </SuspensedView>
          }
        />
        {/* <Route
          path='merchant/list/edit/:merchantCode'
          element={
            <SuspensedView>
              <MerchantListPage_EditMerchant />
            </SuspensedView>
          }
        /> */}
        <Route
          path='merchant/fee'
          element={
            <SuspensedView>
              <MerchantFeePage />
            </SuspensedView>
          }
        />
        <Route
          path='/merchant/fee/create'
          element={
            <SuspensedView>
              <MerchantFeePage_AddMerchantFee />
            </SuspensedView>
          }
        />
        <Route
          path='/merchant/fee/edit/:merchantCode'
          element={
            <SuspensedView>
              <MerchantFeePage_EditMerchantFee />
            </SuspensedView>
          }
        />
        <Route
          path='/sms/list'
          element={
            <SuspensedView>
              <SmsManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='/sms/list/:smsId'
          element={
            <SuspensedView>
              <SmsManagementPage_View />
            </SuspensedView>
          }
        />
        <Route
          path='/sms/last-active'
          element={
            <SuspensedView>
              <SMSLastActive_List />
            </SuspensedView>
          }
        />
        <Route
          path='/request/pending'
          element={
            <SuspensedView>
              <RequestManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='/request/list'
          element={
            <SuspensedView>
              <RequestManagementPage_List />
            </SuspensedView>
          }
        />
        <Route
          path='/request/:reqId'
          element={
            <SuspensedView>
              <RequestManagementPage_View_Url />
            </SuspensedView>
          }
        />
        <Route
          path='/transaction/list'
          element={
            <SuspensedView>
              <TransactionManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='/transaction/pending'
          element={
            <SuspensedView>
              <TransactionManagementPage_Pending />
            </SuspensedView>
          }
        />
        <Route
          path='/transaction/:txnId'
          element={
            <SuspensedView>
              <TransactionManagementPage_View />
            </SuspensedView>
          }
        />
        <Route
          path='/deposit/list'
          element={
            <SuspensedView>
              <DepositManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='/deposit/:requestId'
          element={
            <SuspensedView>
              <DepositManagementPage_Edit />
            </SuspensedView>
          }
        />
        <Route
          path='/deposit/pending'
          element={
            <SuspensedView>
              <DepositManagementPage_Pending_List />
            </SuspensedView>
          }
        />
        <Route
          path='/deposit/manual-deposit-creation'
          element={
            <SuspensedView>
              <DepositManagementPage_ManualDeposit />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/health'
          element={
            <SuspensedView>
              <WalletHealth_List />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/list'
          element={
            <SuspensedView>
              <WalletListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/list/create'
          element={
            <SuspensedView>
              <WalletListPage_Add />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/list/edit/:walletAccountNo'
          element={
            <SuspensedView>
              <WalletListPage_Edit />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/utilization'
          element={
            <SuspensedView>
              <WalletUtilizationPage />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/assign'
          element={
            <SuspensedView>
              <WalletListPage_Assign />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/activity'
          element={
            <SuspensedView>
              <WalletActivityPage />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/activity/:walletAccName'
          element={
            <SuspensedView>
              <WalletActivityPage_Edit />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/assignment/list'
          element={
            <SuspensedView>
              <WalletAssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='/wallet/assignment/edit/:id'
          element={
            <SuspensedView>
              <WalletAssignmentPage_Edit />
            </SuspensedView>
          }
        />
        <Route
          path='/whitelist/source'
          element={
            <SuspensedView>
              <Whitelist_Source />
            </SuspensedView>
          }
        />
        <Route
          path='/whitelist/source/create'
          element={
            <SuspensedView>
              <Whitelist_Source_Add />
            </SuspensedView>
          }
        />
        <Route
          path='/whitelist/source/edit/:walletCd'
          element={
            <SuspensedView>
              <Whitelist_Source_Edit />
            </SuspensedView>
          }
        />
        <Route
          path='/whitelist/center'
          element={
            <SuspensedView>
              <Whitelist_Service />
            </SuspensedView>
          }
        />
        <Route
          path='/whitelist/center/create'
          element={
            <SuspensedView>
              <Whitelist_Service_Add />
            </SuspensedView>
          }
        />
        <Route
          path='/whitelist/center/edit/:walletCd'
          element={
            <SuspensedView>
              <Whitelist_Service_Edit />
            </SuspensedView>
          }
        />
        <Route
          path='/withdrawal/list'
          element={
            <SuspensedView>
              <Withdrawal_List_Page />
            </SuspensedView>
          }
        />
        <Route
          path='/withdrawal/pending'
          element={
            <SuspensedView>
              <Withdrawal_Pending_List />
            </SuspensedView>
          }
        />
        <Route
          path='/withdrawal/bulk-approval'
          element={
            <SuspensedView>
              <Bulk_Withdrawal />
            </SuspensedView>
          }
        />
        <Route
          path='/withdrawal/manual-withdrawal-creation'
          element={
            <SuspensedView>
              <WithdrawalManagement_ManualWithdrawal />
            </SuspensedView>
          }
        />
        <Route
          path='/settlement/pending'
          element={
            <SuspensedView>
              <Settlement_Pending_List />
            </SuspensedView>
          }
        />
        <Route
          path='/settlement/list'
          element={
            <SuspensedView>
              <Settlement_List />
            </SuspensedView>
          }
        />
        <Route
          path='/settlement/create'
          element={
            <SuspensedView>
              <Settlement_Create />
            </SuspensedView>
          }
        />
        <Route
          path='/reporting/daily'
          element={
            <SuspensedView>
              <Daily_Reporting_List />
            </SuspensedView>
          }
        />
        <Route
          path='/reporting/monthly'
          element={
            <SuspensedView>
              <Monthly_Reporting_List />
            </SuspensedView>
          }
        />
        <Route
          path='/reporting/detailed'
          element={
            <SuspensedView>
              <Detailed_Reporting_List />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/credit-reload'
          element={
            <SuspensedView>
              <CreditReload_List />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/commission-withdrawal'
          element={
            <SuspensedView>
              <CommissionWithdrawal_List />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/summary'
          element={
            <SuspensedView>
              <AgentSummary_List />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/summary/:agentUsername'
          element={
            <SuspensedView>
              <AgentSummary_Details />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/previous-day-summary'
          element={
            <SuspensedView>
              <PreviousDayAgentSummary_List />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/previous-day-summary/:agentUsername'
          element={
            <SuspensedView>
              <PreviousDayAgentSummary_Details />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/credit-reload/create'
          element={
            <SuspensedView>
              <CreditReload_Create />
            </SuspensedView>
          }
        />
        <Route
          path='/agent/commission-withdrawal/create'
          element={
            <SuspensedView>
              <CommissionWithdrawal_Comm_Create />
            </SuspensedView>
          }
        />
        <Route
          path='/deposit/match-sms'
          element={
            <SuspensedView>
              <DepositManagementPage_MatchSMS />
            </SuspensedView>
          }
        />
        <Route
          path='/reporting/summary-daily'
          element={
            <SuspensedView>
              <Summary_Daily_Reporting_List />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
