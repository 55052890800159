import axios from 'axios'
import {modifyDT} from '../../../components/ModifyDT'
import {getCurrentDateTime} from '../../../components/GetCurrentDatetime'
import {modifyKeys} from '../../../components/ModifyKeys'

const keyMap = {
  destWalletName: 'Destination Account Name',
  amount: 'Amount',
  srcWalletName: 'Source Account Name',
  completedDT: 'Completed DateTime',
  fee: 'Fee',
  merchantTxnId: 'Merchant Transaction ID',
  receivedDT: 'Received DateTime',
  submittedTxnId: 'Submitted Transaction ID',
  destWalletAcc: 'Destination Account Number',
  referenceId: 'Reference ID',
  merchantName: 'Merchant Name',
  walletCd: 'Bank',
  transType: 'Transaction Type',
  srcWalletAcc: 'Source Account Number',
  clientIP: 'Client IP',
  submittedDT: 'Submitted DateTime',
  customerCd: 'Customer Code',
  merchantCd: 'Merchant Code',
  status: 'Transaction Status',
  matchStatus: 'Match Status',
  matchedTxnId: 'Matched Txn ID',
  successBy: 'Transaction Success By',
  agentComm: 'Agent Commission',
  txnAgentCommFee: 'Agent Commission Fee',
  // Add more key mappings here
}

function escapeCsvValue(value: string): string {
  // If the value contains a comma or line breaks, wrap it in double quotes and escape existing double quotes and line breaks
  if (value.includes(',') || value.includes('\n') || value.includes('\r')) {
    return `"${value.replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`
  }
  return value
}

// modifyKeys(formData, keyMap)

function generateFile(formData: any) {
  let dataset = formData.map((obj: any) => {
    const updatedObj = {...obj}
    delete updatedObj.transId
    delete updatedObj.reqId
    return updatedObj
  })

  //Modify receivedDT
  dataset = modifyDT(dataset, 'Received DateTime')
  dataset = modifyDT(dataset, 'SMS Transaction DateTime')

  const header = Object.keys(dataset[0]).join(',')
  const rows = dataset.map((transaction: any) =>
    Object.values(transaction)
      .map((value: any) => escapeCsvValue(value.toString()))
      .join(',')
  )

  const csvContent = `${header}\n${rows.join('\n')}`
  const blob = new Blob([csvContent], {type: 'text/csv'})

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `detailed_reporting_${getCurrentDateTime()}.csv`
  a.click()

  URL.revokeObjectURL(url)
}

export default function DetailedReporting_Export_CSV(filterValues: any, setLoading: any): void {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  setLoading(true)

  let merchantCd = filterValues.merchantCd.trim()
  let timeZone = filterValues.timeZone.trim()
  let basedOn = filterValues.basedOn.trim()
  let transType = filterValues.transType.trim()
  let dateTimeFrom = filterValues.dateTimeFrom.trim()
  let dateTimeTo = filterValues.dateTimeTo.trim()
  let wallets = filterValues.wallets

  //   values.wallets = wallets

  //   setStartDate(dateTimeFrom)
  //   setEndDate(dateTimeTo)

  //   setFilterOptions(values)

  let submissionData = {}

  if (filterValues.merchantCd !== '') {
    submissionData = {
      session: session,
      merchantCd: merchantCd,
      ...(wallets && {accountNos: wallets}),
      timezone: timeZone,
      basedOn: basedOn,
      transType: transType,
      fromDate: dateTimeFrom,
      toDate: dateTimeTo,
      //Pagination
      noOfRec: 0,
      offSet: 0,
    }
  } else {
    submissionData = {
      session: session,
      timezone: timeZone,
      ...(wallets && {accountNos: wallets}),
      basedOn: basedOn,
      transType: transType,
      fromDate: dateTimeFrom,
      toDate: dateTimeTo,
      //Pagination
      noOfRec: 0,
      offSet: 0,
    }
  }

  axios
    .post(
      `${SISPAY_API}/merchant/admin/detailedreport`,
      {
        request: submissionData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response) {
      let formData = modifyKeys(response.data.result.merchantDetailedReport, keyMap)

      generateFile(formData)

      setLoading(false)
    })
    .catch(function (error) {})
}
