import React, {Ref, useEffect, useState} from 'react'
import {Column, useFlexLayout, useResizeColumns, useTable} from 'react-table'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Styles_NoAction} from '../../components/Table_Style_NoAction'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {metricsArray} from '../../components/MetricsArray'
import FormatNumber from '../../components/FormatNumber'
import deleteKeysFromObjects from '../../components/DeleteKeysFromObjects'
import {Summary_Daily_Reporting_Filter} from './components/Summary_Daily_Filter'
import {Export_PDF_Summary_Daily_Report} from './components/Export_PDF_Summary_Daily'

const Summary_Daily_Reporting_List: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [isAction, setIsAction] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [metrics, setMatrics] = useState<any>(undefined)

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  useEffect(() => {
    let metrics = localStorage.getItem('dailyReportMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table::START
  interface Data {
    date: string
    totalCommissionFee: string
    totalSettlement: string
    totalFee: string
    totalDeposit: string
    totalDepositFee: string
    totalWithdrawal: string
    totalTopUpFee: string
    totalTopup: string
    totalSettlementFee: string
    totalWithdrawalFee: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Total Deposit',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalDeposit))
        },
      },
      {
        Header: 'Total Deposit Fee',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalDepositFee))
        },
      },
      {
        Header: 'Total Withdrawal',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalWithdrawal))
        },
      },
      {
        Header: 'Total Withdrawal Fee',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalWithdrawalFee))
        },
      },
      {
        Header: 'Total Topup',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalTopup))
        },
      },
      {
        Header: 'Total Topup Fee',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalTopUpFee))
        },
      },

      {
        Header: 'Total Settlement',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalSettlement))
        },
      },
      {
        Header: 'Total Settlement Fee',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalSettlementFee))
        },
      },
      {
        Header: 'Total Fee',
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalFee))
        },
      },
      //   {
      //     Header: 'Total Commission Fee',
      //     accessor: (row: any) => {
      //       return FormatNumber(Number(row.totalCommissionFee))
      //     },
      //   },
    ],
    []
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('dailyReportMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  const keyMap = {
    date: 'Date',
    // totalCommissionFee: 'Total Commission Fee',
    totalSettlement: 'Total Settlement',
    totalFee: 'Total Fee',
    totalDeposit: 'Total Deposit',
    totalDepositFee: 'Total Deposit Fee',
    totalWithdrawal: 'Total Withdrawal',
    totalTopup: 'Total Topup',
    totalTopUpFee: 'Total Topup Fee',
    totalSettlementFee: 'Total Settlement Fee',
    totalWithdrawalFee: 'Total Withdrawal Fee',
  }

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  //data-bs-toggle='modal'
                  //data-bs-target='#kt_modal_bulk_active'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      deleteKeysFromObjects(formData, ['totalJustpayFee']),
                      keyMap,
                      startDate,
                      endDate,
                      'Daily_Report'
                    )
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_bulk_deactive'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      deleteKeysFromObjects(formData, ['totalJustpayFee']),
                      keyMap,
                      startDate,
                      endDate,
                      'Daily_Report'
                    )
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_bulk_deactive'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_PDF_Summary_Daily_Report(
                      deleteKeysFromObjects(formData, ['totalJustpayFee']),
                      startDate,
                      endDate
                    )
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
        </div>

        <div className='m-0 d-flex'>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Summary_Daily_Reporting_Filter
        setFormData={setFormData}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <Styles_NoAction>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Styles_NoAction>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default Summary_Daily_Reporting_List
